
import { Component, Prop, Watch } from 'vue-property-decorator'
import { debounce } from 'lodash'
import VueStrong from '@/VueStrong'

// TODO: remove from here and use from API somehow (eg: symlink)
type FacebookEntity = { id: string; name: string; status: string; preview: string; thumbnail: string }
type Cursors = {
  after: string
  before: string
}

@Component
export default class FacebookEntities extends VueStrong {
  @Prop({ type: String, required: true }) type!: 'ad' | 'adset' | 'campaign'
  @Prop({ type: Array, required: true }) value!: string[]

  loading = false
  error = ''

  filters = {
    name: '',
    status: ''
  }

  data: FacebookEntity[] = []
  cursors: Cursors = { after: '', before: '' }

  fakeModel: string[] = []

  debounceGetEntities = debounce(this.getEntities, 500)
  get accountId() {
    return this.$store.state.account.adAccount?.id
  }

  get snackbarError() {
    return !!this.error
  }

  set snackbarError(value: boolean) {
    if (!value) this.error = ''
  }

  get adAccountId() {
    return this.$store.state.account.adAccount?.id
  }

  mounted() {
    this.getEntities()
  }

  @Watch('adAccountId')
  @Watch('type')
  onTypeChange() {
    this.cursors.after = ''
    this.cursors.before = ''
    this.getEntities()
  }

  async getEntities(action?: 'next' | 'prev') {
    try {
      if (!this.accountId) return
      this.loading = true

      const filters = []
      if (this.filters.name) filters.push({ field: 'name', value: this.filters.name, operator: 'CONTAIN' })
      if (this.filters.status) filters.push({ field: 'effective_status', value: [this.filters.status], operator: 'IN' })
      // EQUAL, NOT_EQUAL, GREATER_THAN, GREATER_THAN_OR_EQUAL, LESS_THAN, LESS_THAN_OR_EQUAL, IN_RANGE, NOT_IN_RANGE, CONTAIN, NOT_CONTAIN, IN, NOT_IN, STARTS_WITH, ENDS_WITH, ANY, ALL, AFTER, BEFORE, ON_OR_AFTER, ON_OR_BEFORE, NONE, TOP
      const params = { accountId: this.accountId, filters: JSON.stringify(filters) }

      if (action === 'next') params['after'] = this.cursors.after
      else if (action === 'prev') params['before'] = this.cursors.before

      const { data } = await this.$store.state.apiClient.listFacebookEntities(this.type, params)

      this.data = data.data
      this.cursors = data.cursors
    } catch (error) {
      this.error = error.response?.data?.error || error.message || error
    }
    this.loading = false
  }

  get selectedIndexes() {
    return this.data.reduce((acc, item, index) => {
      if (this.value.includes(item.id)) acc.push(index)
      return acc
    }, [])
  }

  get selectedIds() {
    return this.data.reduce((acc, item) => {
      if (this.value.includes(item.id)) acc.push(item.id)
      return acc
    }, [])
  }

  onChange(indexes: number[]) {
    indexes = indexes.filter(index => typeof index === 'number')
    const ids = indexes.map(index => this.data[index].id)
    this.$emit('input', ids)
  }
}
