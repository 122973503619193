
import { Component } from 'vue-property-decorator'
import { debounce } from 'lodash'
import VueStrong from '@/VueStrong'
import AutomationRuleGroup from '@/modules/automation/components/RuleGroup.vue'
import FacebookEntities from '@/modules/automation/components/FacebookEntities.vue'
import UpsertTarget from '@/modules/automation/components/UpsertTarget.vue'
import defaultRule from '@/modules/automation/data/defaultRule'
import axios from 'axios'
import NumberInput from '@/components/NumberInput.vue'

@Component({
  components: {
    AutomationRuleGroup,
    FacebookEntities,
    UpsertTarget,
    NumberInput
  }
})
export default class AutomationCreate extends VueStrong {
  step = 1
  loading = false
  debounceUpsertAutomation = debounce(this.upsertAutomation, 500)
  error = ''
  snackbarSuccess = false

  entityLinkingStrategy: 'manual' | 'automated' = 'manual'
  entitiesFilters = {
    statuses: [],
    name: ''
  }

  file = null
  openedAction = null
  editingAction = null

  valid = true
  campaignNameError = false
  targetError = false

  frequencies = [
    { text: '15 minutes', value: 15 },
    { text: 'Half an hour', value: 30 },
    { text: 'One hour', value: 60 },
    { text: 'One day', value: 60 * 24 }
  ]

  metric = [
    { key: 'Fixed value', value: 'fixed' },
    { key: 'Percentage', value: 'percentage' }
  ]

  newAutomation: Partial<yakkyo.IFacebookAutomation> = {
    name: '',
    description: '',

    entityType: 'campaign',
    entityIds: [],
    entityRules: [],

    category: 'Other',
    tags: [],
    picture: 'https://s3-eu-west-1.amazonaws.com/yakkyofy-bulk-logos/nU3NSpH78HMznoUUS6ZqPc.jpg',
    enabled: true,

    actions: [
      {
        kind: 'pause',
        params: {
          amount: 0,
          amountCap: 0,
          amountMetric: 'fixed'
        },
        frequency: 15 // in minutes
      }
    ],

    rule: {
      logic: 'AND',
      rules: [{ ...defaultRule }],
      rulesGroups: []
    }
  }

  get snackbarError() {
    return !!this.error
  }

  set snackbarError(value: boolean) {
    if (!value) this.error = ''
  }

  get emptyEntityIds() {
    return !this.newAutomation.entityIds.length
  }

  get emptyFilters() {
    return !this.entitiesFilters.statuses.length || !this.entitiesFilters.name
  }

  get isCreatingAutomation() {
    return this.$route.params.id && this.$route.params.id === 'new'
  }

  get actionLabel() {
    switch (this.openedAction.kind) {
      case 'increase-budget':
        return 'Select the metric and the amount of budget increase:'
      case 'decrease-budget':
        return 'Select the metric and the amount of budget decrease:'
      case 'increase-bid':
        return 'Select the metric and the amount of bid increase:'
      case 'decrease-bid':
        return 'Select the metric and the amount of bid decrease:'
      default:
        return ''
    }
  }
  get amountCapLabel() {
    switch (this.openedAction.kind) {
      case 'increase-budget':
        return 'Maximum budget cap:'
      case 'decrease-budget':
        return 'Minimum budget cap:'
      case 'increase-bid':
        return 'Maximum bid cap:'
      case 'decrease-bid':
        return 'Minimum bid cap:'
      default:
        return ''
    }
  }
  get amountLabel() {
    const metric =
      !!this.openedAction.params.amountMetric && this.openedAction.params.amountMetric === 'fixed'
        ? 'value'
        : 'percentage'
    switch (this.openedAction.kind) {
      case 'increase-budget':
        return `Increase by ${metric}: `
      case 'decrease-budget':
        return `Decrease by ${metric}: `
      case 'increase-bid':
        return `Increase by ${metric}: `
      case 'decrease-bid':
        return `Decrease by ${metric}: `
      default:
        return ''
    }
  }

  get actionKinds() {
    const baseActions = [
      'pause',
      'start',
      'duplicate',
      'delete',
      'send-notification',
      'add-to-name',
      'remove-from-name'
    ]
    if (this.newAutomation.entityType === 'campaign')
      baseActions.push('increase-budget', 'decrease-budget', 'set-budget')
    else if (this.newAutomation.entityType === 'adset')
      baseActions.push('increase-budget', 'decrease-budget', 'set-budget', 'increase-bid', 'decrease-bid', 'set-bid')
    return baseActions
  }

  setBudgetAmount(v: any) {
    this.openedAction.params.amount = v
  }

  async getAutomation() {
    this.loading = true
    try {
      const { data } = await this.$store.state.apiClient.getAutomation(this.$route.params.id)
      data.actions.map(a => {
        if (!a.params) a.params = {}
      })

      if (data.entityRules && data.entityRules.length) {
        const name = data.entityRules.find(r => r.field === 'name')
        if (name) this.entitiesFilters.name = name.value
        const statuses = data.entityRules.find(r => r.field === 'status')
        if (statuses) this.entitiesFilters.statuses = statuses.value.replace(/\[|\]/g, '').split(',')
      }
      if (data.entityIds.length) this.entityLinkingStrategy = 'manual'
      else this.entityLinkingStrategy = 'automated'
      this.newAutomation = data
    } catch (error) {
      console.log(error)
      this.snackbarError = true
    }
    this.loading = false
  }

  async created() {
    // params id
    if (!this.isCreatingAutomation) await this.getAutomation()
  }

  parseEntitiesFilters() {
    const filters = []
    if (this.entitiesFilters.statuses.length) {
      filters.push({
        field: 'status',
        operator: 'IN',
        value: `[${this.entitiesFilters.statuses.join(',')}]`,
        timeFrame: undefined
      })
    }
    if (this.entitiesFilters.name) {
      filters.push({
        field: 'name',
        operator: 'CONTAIN',
        value: this.entitiesFilters.name,
        timeFrame: undefined
      })
    }
    return filters
  }

  setEntityRules(rules) {
    this.entitiesFilters = rules
    // this.parseEntitiesFilters()
  }

  async upsertAutomation() {
    if (!this.newAutomation.name) {
      this.error = 'Please fill in the automation name'
      return (this.campaignNameError = true)
    }
    if (this.emptyEntityIds && this.emptyFilters) {
      this.error = 'Please choose automation target'
      return (this.targetError = true)
    }
    this.loading = true
    try {
      if (this.entityLinkingStrategy === 'automated') {
        this.newAutomation.entityIds = []
        this.newAutomation.entityRules = this.parseEntitiesFilters()
      } else this.newAutomation.entityRules = []
      if (this.isCreatingAutomation) await this.$store.dispatch('automation/post', this.newAutomation)
      else
        await this.$store.dispatch('automation/update', {
          id: this.newAutomation._id,
          automation: this.newAutomation
        })
      this.snackbarSuccess = true
      this.$router.push({ name: 'AutomationsList' })
    } catch (error) {
      this.error = error.response?.data?.error || error.message || error
      console.error(error)
    }
    this.loading = false
  }

  async onFileChanged(file) {
    try {
      if (!file) return
      this.loading = true
      const formData = new FormData()
      formData.append('file', file)
      const { data } = await axios.post(`${process.env.VUE_APP_MEDIA_BASE_URL}/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': this.$store.state.token }
      })
      this.newAutomation.picture = data.result.url

      this.snackbarSuccess = true
    } catch (error) {
      console.log(error)
      this.snackbarError = true
    } finally {
      file = null
      this.loading = false
    }
  }

  changeName(value) {
    // event.target.blur()
    this.newAutomation.name = value
  }

  openFileUpload() {
    document.getElementById('file').click()
  }

  getActionInfo(
    action:
      | 'pause'
      | 'start'
      | 'duplicate'
      | 'delete'
      | 'send-notification'
      | 'add-to-name'
      | 'remove-from-name'
      | 'increase-budget'
      | 'decrease-budget'
      | 'set-budget'
      | 'increase-bid'
      | 'decrease-bid'
      | 'set-bid'
  ) {
    const entity =
      this.newAutomation.entityType === 'campaign'
        ? 'campaign'
        : this.newAutomation.entityType === 'adset'
        ? 'ad set'
        : 'ad'
    switch (action) {
      case 'pause':
        return { icon: 'mdi-pause', description: 'Pause Campaign when conditions performed.' }
      case 'start':
        return { icon: 'mdi-play', description: 'Start Campaign when conditions performed.' }
      case 'duplicate':
        return { icon: 'mdi-content-duplicate', description: 'Duplicate Campaign when conditions performed.' }
      case 'delete':
        return { icon: 'mdi-delete', description: 'Delete Campaign when conditions performed.' }
      case 'send-notification':
        return { icon: 'mdi-bell', description: 'Notify to email when conditions performed.' }
      case 'add-to-name':
        return { icon: 'mdi-format-text', description: 'Add a text or expression before the Campaign name.' }
      case 'remove-from-name':
        return { icon: 'mdi-pencil-remove', description: 'Remove a text or expression from the end of Campaign name' }
      case 'increase-budget':
        return { icon: 'mdi-trending-up', description: `Increase budget of ${entity} when conditions performed` }
      case 'decrease-budget':
        return { icon: 'mdi-trending-down', description: `Decrease budget of ${entity} when conditions performed` }
      case 'set-budget':
        return {
          icon: 'mdi-format-vertical-align-top',
          description: `Set budget of ${entity} when conditions performed`
        }
      case 'increase-bid':
        return { icon: 'mdi-trending-up', description: `Increase bid of ${entity} when conditions performed` }
      case 'decrease-bid':
        return { icon: 'mdi-trending-down', description: `Decrease bid of ${entity} when conditions performed` }
      case 'set-bid':
        return { icon: 'mdi-format-vertical-align-top', description: `Set bid of ${entity} when conditions performed` }
    }
  }

  getActionFrequency(frequency) {
    return this.frequencies.find(f => f.value === frequency).text
  }

  addNewAction() {
    this.openedAction = {
      kind: 'pause',
      params: {
        amount: 0,
        amountCap: 0,
        amountMetric: 'fixed'
      },
      frequency: 15
    }
  }

  addAction() {
    ;(this.$refs.form as any).validate()
    if (this.openedAction.kind === 'duplicate' && !this.openedAction.params.duplicationAction) return
    if (this.openedAction.kind === 'add-to-name' && !this.openedAction.params.additionalText) return
    if (this.openedAction.kind === 'remove-from-name' && !this.openedAction.params.additionalText) return

    if (this.editingAction === null) this.newAutomation.actions.push(this.openedAction)
    else this.newAutomation.actions[this.editingAction] = this.openedAction
    this.openedAction = null
    this.editingAction = null
  }

  editAction(index) {
    this.editingAction = index
    this.openedAction = this.newAutomation.actions[index]
  }

  updateEntityType(value: 'campaign' | 'adset' | 'ad') {
    const baseAction = {
      kind: 'pause',
      params: {
        amount: 0,
        amountCap: 0,
        amountMetric: 'fixed'
      },
      frequency: 15
    }
    this.newAutomation.entityType = value
    const filteredActions = []

    if (value === 'campaign') {
      this.newAutomation.actions.map(a => {
        if (!a.kind.includes('bid')) filteredActions.push(a)
      })
    } else if (value === 'ad') {
      this.newAutomation.actions.map(a => {
        if (!a.kind.includes('bid') && !a.kind.includes('budget')) filteredActions.push(a)
      })
    }

    if (!filteredActions.length) this.newAutomation.actions = [baseAction as any]
    else this.newAutomation.actions = filteredActions
  }
}
