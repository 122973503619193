var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-snackbar',{attrs:{"top":"","right":"","color":"primary","timeout":5000},model:{value:(_vm.snackbarError),callback:function ($$v) {_vm.snackbarError=$$v},expression:"snackbarError"}},[_vm._v(_vm._s(_vm.error))]),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Search by title","clearable":"","dense":""},on:{"input":_vm.debounceGetEntities},model:{value:(_vm.filters.name),callback:function ($$v) {_vm.$set(_vm.filters, "name", $$v)},expression:"filters.name"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":[
          'ACTIVE',
          'PAUSED',
          'ARCHIVED',
          'DELETED',
          'IN PROCESS',
          'WITH ISSUES',
          'PREAPPROVED',
          'DISAPPROVED',
          'PENDING REVIEW'
        ],"label":"Status","clearable":"","dense":""},on:{"change":_vm.getEntities},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1)],1),_c('v-list',{staticStyle:{"overflow-y":"auto","max-height":"50vh"}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-list-item-group',{attrs:{"value":_vm.selectedIndexes,"active-class":"primary--text","multiple":""},on:{"change":_vm.onChange}},[_vm._l((_vm.data),function(item){return [_c('v-list-item',{key:item.id,scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-avatar',{staticClass:"mr-4",attrs:{"tile":"","size":"64","color":"grey"}},[_c('v-img',{attrs:{"src":item.thumbnail}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_c('v-chip',{attrs:{"outlined":"","color":"secondary","small":""}},[_vm._v(_vm._s(item.status))])],1)],1)]}}],null,true)})]})],2)],1),_c('div',{staticClass:"d-flex",staticStyle:{"justify-content":"center"}},[_c('v-btn',{attrs:{"disabled":!_vm.cursors.before,"small":"","loading":_vm.loading},on:{"click":function($event){return _vm.getEntities('prev')}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"ml-1",attrs:{"disabled":!_vm.cursors.after,"small":"","loading":_vm.loading},on:{"click":function($event){return _vm.getEntities('next')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }