
import { Component, Prop, Watch } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import FacebookEntities from '@/modules/automation/components/FacebookEntities.vue'
import { cloneDeep } from 'lodash'

@Component({ components: { FacebookEntities } })
export default class UpsertTarget extends VueStrong {
  @Prop({ required: true }) entityType!: 'campaign' | 'adset' | 'ad'
  @Prop({ required: true }) entityIds!: []
  @Prop({ required: true }) entityLinkingStrategy!: 'manual' | 'automated'
  @Prop({ required: true, default: { statuses: [], name: '' } }) entitiesFilters!: { statuses: string[]; name: string }

  dialog = false
  errorAlert: '' | 'entityIds' | 'entitiesFilters' = ''

  editingEntityIds = []
  editingEntityType = 'campaign'
  editingLinkingStrategy = 'manual'
  editingFilters = cloneDeep(this.entitiesFilters) || { statuses: [], name: '' }

  get emptyEntityIds() {
    return !this.entityIds.length
  }

  get emptyFilters() {
    return !this.entitiesFilters.statuses.length || !this.entitiesFilters.name
  }

  @Watch('entityIds')
  setEntityIds() {
    this.editingEntityIds = cloneDeep(this.entityIds)
  }

  @Watch('entityType')
  setEntityType() {
    this.editingEntityType = cloneDeep(this.entityType)
  }

  @Watch('entityLinkingStrategy')
  setLinkingStrategy() {
    this.editingLinkingStrategy = cloneDeep(this.entityLinkingStrategy)
  }

  @Watch('entitiesFilters', { deep: true })
  setEntitiesFilters() {
    this.editingFilters = cloneDeep(this.entitiesFilters)
  }

  changeLinkingStrategy(value) {
    this.editingLinkingStrategy = value ? 'manual' : 'automated'
  }

  save() {
    if (this.editingLinkingStrategy === 'automated') {
      if (!this.editingFilters.statuses.length || !this.editingFilters.name)
        return (this.errorAlert = 'entitiesFilters')
      this.$emit('editingEntityType', this.editingEntityType)
      this.$emit('changeLinkingStrategy', this.editingLinkingStrategy)
      this.$emit('entitiesFilters', this.editingFilters)
      this.$emit('editingEntityIds', [])
      this.dialog = false
    } else {
      if (!this.editingEntityIds.length) return (this.errorAlert = 'entityIds')
      this.$emit('editingEntityType', this.editingEntityType)
      this.$emit('changeLinkingStrategy', this.editingLinkingStrategy)
      this.$emit('editingEntityIds', this.editingEntityIds)
      this.$emit('entitiesFilters', { statuses: [], name: '' })
      this.dialog = false
    }
  }
}
